<template>
  <div class="ApplyCardContent">
    <NavHeader />
    <NavHeaderMobile />
    <div class="ApplyCardContainer">
      <div class="applyContent">
        <div class="backContainer" @click="backBtn">
          <a-icon type="arrow-left" /> back
        </div>
        <div class="ApplyCardContainerContent">
          <div class="ApplyCardTitle">Card Application</div>
          <a-steps :current="current" class="card-steps">
            <a-step
              v-for="item in steps"
              :key="item.title"
              :title="item.title"
            />
          </a-steps>
          <a-divider class="divider" />
          <a-spin :spinning="statusLoading">
            <div className="stepsContent">
              <div v-if="current === 0">
                <Step1
                  :nextBtnClick="nextBtnClick"
                  :id="cardTypeId"
                  :kycMsg="kycMsg"
                />
              </div>
              <div v-if="current === 1">
                <Step2
                  :nextBtnClick="nextBtnClick2"
                  :changeCurrent="changeCurrent"
                  :changeStatus="changeStatus"
                  :backBtnClick="backBtnClick"
                  :isPaidBtnClick="isPaidBtnClick"
                  :id="cardTypeId"
                  :kycMsg="kycMsg"
                  :card_fee_order_no="card_fee_order_no"
                />
              </div>
              <div v-if="current === 2 && cardType == 0">
                <Step3
                  :nextBtnClick="nextBtnClick"
                  :id="cardTypeId"
                  :kycMsg="kycMsg"
                  :cardApplicationFee="cardApplicationFee"
                  :cardFeeCurrency="cardFeeCurrency"
                  :currency="currency"
                />
              </div>
              <div v-if="current === 2 && cardType == 1">
                <Step3Credit
                  :nextBtnClick="nextBtnClick"
                  :id="cardTypeId"
                  :cardApplicationFee="cardApplicationFee"
                  :cardFeeCurrency="cardFeeCurrency"
                />
              </div>
              <div v-if="current === 3">
                <Step4
                  :changeCurrent="changeCurrent"
                  :id="cardTypeId"
                  :kycMsg="kycMsg"
                  :status="status"
                  :getKycDetail="getKycDetail"
                />
              </div>
            </div>
          </a-spin>
        </div>
      </div>
    </div>
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import NewFooter from '@/components/NewFooter.vue';
import { getAccountKyc, getAccountKycCard } from '@/utils/api';
import Step1 from './components/Step1/index.vue';
import Step2 from './components/Step2/index.vue';
import Step3 from './components/Step3/index.vue';
import Step3Credit from './components/Step3Credit/index.vue';
import Step4 from './components/Step4/index.vue';

export default {
  name: 'ApplyCard',
  components: {
    NavHeader,
    NavHeaderMobile,
    NewFooter,
    Step1,
    Step2,
    Step3,
    Step3Credit,
    Step4,
  },
  data() {
    return {
      paid_open_card_fee: false,
      current: 0,
      cardTypeId: 0,
      cardApplicationFee: 5,
      cardFeeCurrency: 'USD',
      currency: 'USD',
      cardType: 0,
      statusLoading: true,
      status: null,
      card_fee_order_no: '',
      kycMsg: {},
      steps: [],
    };
  },
  created() {
    this.getParams();
    this.getKycDetail();
  },
  methods: {
    getParams() {
      const {
        cardTypeId, cardType, cardApplicationFee, currency, cardFeeCurrency,
      } = this.$route.query;
      this.cardTypeId = cardTypeId;
      this.cardApplicationFee = cardApplicationFee;
      this.cardFeeCurrency = cardFeeCurrency;
      this.currency = currency;
      this.cardType = cardType;
      // cardType===0为充值卡,1为信用卡
      if (cardType === '0') {
        this.steps = [
          { title: 'Personal Information', content: 'first-content' },
          { title: 'ID Information', content: 'second-content' },
          { title: 'Application Fee', content: 'third-content' },
          { title: 'Under Review', content: 'fourth-content' },
        ];
      } else {
        this.steps = [
          { title: 'Personal Information', content: 'first-content' },
          { title: 'ID Information', content: 'second-content' },
          { title: 'Deposit', content: 'third-content' },
          { title: 'Under Review', content: 'fourth-content' },
        ];
      }
    },
    getKycDetail() {
      const params = {
        cardTypeId: this.$route.query.cardTypeId,
      };
      getAccountKycCard(params).then((res) => {
        this.statusLoading = false;
        if (res.result) {
          if (res.result.length === 0) {
            getAccountKyc().then((kycRes) => {
              if (kycRes.result) {
                this.kycMsg = kycRes.result;
                this.status = kycRes.result.status;
                this.changeSteps(kycRes.result.status);
              }
            });
          } else {
            this.kycMsg = res.result[0] || {};
            this.status = res.result[0].status;
            this.card_fee_order_no = res.result[0].card_fee_order_no;
            this.paid_open_card_fee = res.result[0].paid_open_card_fee;
            this.changeSteps(
              res.result[0].status,
              res.result[0].card_fee_order_no,
            );
          }
        }
      });
    },
    changeSteps(status, cardFeeOrderNo) {
      if (status === 0) {
        // 基础信息提交中
        this.current = 3;
      } else if (status === 1) {
        // 验证通过(激活成功)
        this.current = 3;
      } else if (status === 2) {
        // 基础信息审核通过
        this.current = 0;
      } else if (status === 3) {
        // inst端Kyc审核通过
        this.current = 3;
      } else if (status === 4) {
        // 已提交
        this.current = 3;
      } else if (status === 5) {
        // Railone审核通过
        this.current = 3;
      } else if (status === 6) {
        // 提交开卡申请
        this.current = 3;
      } else if (status === 7) {
        // 拒绝
        this.current = 3;
      } else if (status === 8) {
        // card待激活
        this.current = 3;
      } else if (status === 50) {
        // 卡申请信息
        this.current = 0;
      } else if (status === 51) {
        // 卡申请证件信息
        this.current = 1;
      } else if (status === 52) {
        // 卡申请证件信息
        if (cardFeeOrderNo) {
          this.current = 3;
        } else {
          this.current = 2;
        }
      } else if (status === 53) {
        // 开卡费支付中
        this.current = 2;
      } else if (status === 54) {
        // 开卡费成功
        this.current = 3;
      }
    },
    backBtn() {
      this.$router.push({
        path: '/my',
        query: {
          tab: 'myCard',
        },
      });
    },
    backBtnClick() {
      this.current = this.current - 1;
    },
    nextBtnClick() {
      if (this.current < this.steps.length - 1) {
        this.current = this.current + 1;
      }
    },
    nextBtnClick2() {
      if (this.paid_open_card_fee) {
        this.current = 3;
        this.status = 54;
      } else {
        this.current = 2;
      }
    },
    isPaidBtnClick() {
      this.current = 3;
    },
    changeCurrent(value) {
      this.current = value;
    },
    changeStatus() {
      this.status = 52;
    },
  },
};
</script>

<style lang="scss" scoped>
.ApplyCardContent {
  background: linear-gradient(104.49deg, #fff4ef 0%, #edf6ff 99.7%);
  .ApplyCardContainer {
    max-width: 1280px;
    padding-top: 80px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    min-height: calc(100vh - 100px);
    @media (max-width: 960px) {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
    .backContainer {
      font-size: 18px;
      cursor: pointer;
    }
    .applyContent {
      max-width: 1280px;
      margin: auto;
      .ApplyCardContainerContent {
        background: #fff;
        border-radius: 4px;
        padding: 24px 224px;
        margin: 24px 0;
        @media (max-width: 960px) {
          padding: 24px;
        }
        .ApplyCardTitle {
          font-family: NoumenaFont-Bold;
          font-weight: bold;
          font-size: 40px;
          line-height: 50px;
          text-align: center;
          margin-top: 40px;
          opacity: 0.8;
          color: #000;
        }
        .card-steps {
          margin-top: 60px;
        }
        .divider {
          margin: 40px 0;
        }
        .stepsBottomTips {
          margin-top: 40px;
          span {
            display: block;
            font-size: 14px;
            line-height: 22px;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>
