<template>
  <div>
    <div class="stepContainer">
      <a-space class="inputContainer" :size="20">
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">First name</div>
          <a-input
            class="stepInput"
            :value="firstName"
            placeholder="Please enter your legal first name"
            :disabled="firstNameDisabled"
            @change="(e) => (this.firstName = e.target.value)"
          />
        </a-space>
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Last name</div>
          <a-input
            class="stepInput"
            :value="lastName"
            placeholder="Please enter your legal last name"
            :disabled="!!lastNameDisabled"
            @change="(e) => (this.lastName = e.target.value)"
          />
        </a-space>
      </a-space>
      <a-space class="inputContainer" :size="20">
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Gender</div>
          <a-select
            :options="sexOption"
            :value="gender"
            @change="
              (value) => {
                this.gender = value;
              }
            "
          />
        </a-space>
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Country</div>
          <a-select
            :value="country"
            @change="(value) => (this.country = value)"
          >
            <a-select-option
              v-for="item in countryOption"
              :key="item.key"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-space>
      </a-space>
      <a-space class="inputContainer" :size="20">
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">State</div>
          <a-input
            class="stepInput"
            :value="state"
            placeholder="Please enter the state name"
            @change="(e) => (this.state = e.target.value)"
          />
        </a-space>
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">City</div>
          <a-input
            class="stepInput"
            :value="city"
            placeholder="Please enter the city name"
            @change="(e) => (this.city = e.target.value)"
          />
        </a-space>
      </a-space>
      <a-space class="inputContainer" :size="20">
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Address</div>
          <a-input
            class="stepInput"
            :value="address"
            placeholder="E.g: Room 203,Building A,888 XX Street, XX District"
            @change="(e) => (this.address = e.target.value)"
          >
            <a-tooltip slot="suffix" title="Address information">
              <a-icon type="info-circle" style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </a-input>
        </a-space>
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Zipcode</div>
          <a-input
            class="stepInput"
            :value="zipcode"
            placeholder="please enter zipcode"
            @change="(e) => (this.zipcode = e.target.value)"
          />
        </a-space>
      </a-space>
      <a-space class="inputContainer inputContainerSinger" :size="20">
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Phone number</div>
          <div class="stepInputContainer">
            <a-select
              v-if="!!phoneCode"
              :value="phoneCode"
              :options="codeOption"
              @change="(value) => (this.phoneCode = value)"
            />
            <a-select
              v-else
              placeholder="Phone Code"
              :options="codeOption"
              @change="(value) => (this.phoneCode = value)"
            />
            <a-input
              class="stepInput"
              :value="phone"
              placeholder="please enter your phone number"
              @change="(e) => (this.phone = e.target.value)"
            />
          </div>
        </a-space>
      </a-space>
    </div>
    <div class="stepsButtonContainer">
      <a-button type="primary" class="stepsButton" @click="submit">
        Next
      </a-button>
    </div>
  </div>
</template>

<script>
import countryList from '@/utils/Country.json';
import { submitAccountKycCard } from '@/utils/api';

export default {
  name: 'Step1',
  props: {
    id: {
      type: String,
      require: false,
      default: null,
    },
    kycMsg: {
      type: Object,
      require: true,
      default() {
        return {};
      },
    },
    nextBtnClick: {
      type: Function,
      require: true,
    },
  },
  components: {},
  data() {
    return {
      deepkycMsg: null,
      countryOption: [],
      codeOption: [],
      firstNameDisabled: false,
      lastNameDisabled: false,
      // countryDisabled: false,
      firstName: '',
      lastName: '',
      gender: '',
      country: '',
      state: '',
      city: '',
      address: '',
      zipcode: '',
      doc_type: '',
      phoneCode: null,
      phone: '',
      mail: '',
      emailCode: '',
      emailToken: '',
      sexOption: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ],
    };
  },
  mounted() {
    const initCode = countryList.map((item) => {
      const tmp = {
        value: item.code,
        label: `+ ${item.code} ${item.name_en}`,
        code: item.code,
        key: item.name_cn,
      };
      return tmp;
    });
    const initCountry = countryList.map((item) => {
      const tmp = {
        value: item.countryCode,
        label: item.name_en,
        code: item.code,
        key: item.name_cn,
      };
      return tmp;
    });
    this.countryOption = initCountry;
    this.codeOption = initCode;
    this.deepkycMsg = this.kycMsg;
    if (this.kycMsg.first_name) {
      this.firstNameDisabled = true;
    }
    if (this.kycMsg.last_name) {
      this.lastNameDisabled = true;
    }
    // if (this.kycMsg.country) {
    //   this.countryDisabled = true;
    // }
    this.firstName = this.kycMsg.first_name;
    this.lastName = this.kycMsg.last_name;
    this.mail = this.kycMsg.mail;
    this.country = this.kycMsg.country;
    this.gender = this.kycMsg.gender;
    this.state = this.kycMsg.state;
    this.city = this.kycMsg.city;
    this.address = this.kycMsg.address;
    this.phone = this.kycMsg.mobile;
    this.doc_type = this.kycMsg.doc_type;
    if (this.kycMsg.country_code) {
      this.phoneCode = this.kycMsg.country_code;
    }
    this.zipcode = this.kycMsg.zipcode;
  },
  watch: {
    kycMsg: {
      handler(newV) {
        this.deepkycMsg = JSON.parse(JSON.stringify(newV));
        if (this.deepkycMsg.first_name) {
          this.firstNameDisabled = true;
        }
        if (this.deepkycMsg.last_name) {
          this.lastNameDisabled = true;
        }
        // if (this.deepkycMsg.country) {
        //   this.countryDisabled = true;
        // }
        this.firstName = this.deepkycMsg.first_name;
        this.lastName = this.deepkycMsg.last_name;
        this.mail = this.deepkycMsg.mail;
        this.country = this.deepkycMsg.country;
        this.gender = this.deepkycMsg.gender;
        this.state = this.deepkycMsg.state;
        this.city = this.deepkycMsg.city;
        this.address = this.deepkycMsg.address;
        this.phone = this.deepkycMsg.mobile;
        if (this.deepkycMsg.country_code) {
          this.phoneCode = this.deepkycMsg.country_code;
        }
        this.zipcode = this.deepkycMsg.zipcode;
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      const params = {
        first_name: this.firstName,
        last_name: this.lastName,
        gender: this.gender,
        city: this.city,
        state: this.state,
        doc_type: this.doc_type,
        country: this.country,
        country_code: this.phoneCode,
        mobile: this.phone,
        mail: this.mail,
        address: this.address,
        zipcode: this.zipcode,
        status: 51,
        card_type_id: this.id,
        // email_verification_code: this.emailCode,
        // email_verification_token: this.emailToken,
      };
      if (!this.gender) {
        this.$message.error('Please select your gender');
      } else if (!this.firstName) {
        this.$message.error('Please enter your first name');
      } else if (!this.lastName) {
        this.$message.error('Please enter your last name');
      } else if (!this.city) {
        this.$message.error('Please enter your city');
      } else if (!this.state) {
        this.$message.error('Please enter your state');
      } else if (!this.country) {
        this.$message.error('Please select your country');
      } else if (!this.phoneCode) {
        this.$message.error('Please select your phone code');
      } else if (!this.phone) {
        this.$message.error('Please enter your phone');
      } else if (!this.address) {
        this.$message.error('Please select your address');
      } else if (!this.zipcode) {
        this.$message.error('Please select your zipcode');
      } else {
        submitAccountKycCard(params).then((res) => {
          this.$message.success(`${res.msg}`);
          this.nextBtnClick();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stepContainer {
  .inputContainer {
    margin-top: 20px;
    @media (max-width: 960px) {
      display: block;
      margin-top: 0;
      /deep/ .ant-space-item {
        width: 100%;
      }
    }
    width: 100%;
    /deep/ .ant-space-item {
      flex: 1;
    }
    .inputContent {
      width: 100%;
      @media (max-width: 960px) {
        margin-top: 20px;
      }
      .inputLabel {
        font-size: 16px;
      }
      .stepInput {
        height: 60px;
        border-radius: 8px;
        /deep/ input {
          height: 60px;
          border-radius: 8px;
        }
      }
      .ant-select {
        width: 100%;
        height: 60px;
        /deep/ .ant-select-selection {
          height: 60px !important;
          border-radius: 8px;
          .ant-select-selection__rendered {
            height: 60px !important;
            line-height: 60px;
          }
        }
      }
      .ant-picker {
        height: 60px;
        width: 100%;
        border-radius: 8px;
      }
      .uploadBtn {
        margin-left: 20px;
        border-radius: 4px;
        font-size: 0;
        span {
          font-size: 14px;
        }
        img {
          height: 24px;
          margin-left: 10px;
        }
      }
      .idCardContainer {
        width: 100%;
        height: 200px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        text-align: center;
        margin-bottom: 10px;
        img {
          height: 180px;
          margin-top: 10px;
        }
      }
      .uploadDescribe {
        display: block;
        font-size: 14px;
        line-height: 24px;
        opacity: 0.3;
      }
      .amountTips {
        text-align: end;
        span {
          font-size: 14px;
          opacity: 0.5;
        }
      }
      .inputBottom {
        color: #0045ff;
        font-size: 20px;
      }
    }
  }
  .inputContainerSinger {
    width: 405px;
    @media (max-width: 960px) {
      width: 100%;
    }
    .inputContent {
      .ant-select {
        width: 220px;
      }
    }
  }
  .stepInputContainer {
    display: flex;
    flex-direction: row;
    .ant-select {
      width: 300px;
      margin-right: 20px;
    }
    .sendBtn {
      margin-left: 40px;
      height: 60px;
      width: 300px;
      border-radius: 8px;
    }
  }
}
.stepsButtonContainer {
  margin-top: 60px;
  text-align: center;
  .stepsButton {
    width: 300px;
    height: 60px;
    border-radius: 8px;
    margin: 0 8px 20px;
    background-color: #0045ff;
    border-color: #0045ff;
    @media (max-width: 960px) {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
</style>
