<template>
  <a-spin :spinning="loading">
    <div class="stepContainer">
      <div class="step4Container" v-if="status === 0">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">Under Review</div>
      </div>
      <!-- <div class="step4Container" v-if="status === 1">
        <a-icon
          class="topImg"
          type="check-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">激活成功</div>
      </div> -->
      <div class="step4Container" v-if="status === 2">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">Under Review</div>
      </div>
      <div class="step4Container" v-if="status === 3">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">Under Review</div>
      </div>
      <div class="step4Container" v-if="status === 4">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">Under Review</div>
      </div>
      <div class="step4Container" v-if="status === 5">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">Under Review</div>
      </div>
      <div class="step4Container" v-if="status === 6">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">Under Review</div>
      </div>
      <div class="step4Container" v-if="status === 7">
        <a-icon
          class="topImg"
          type="close-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <h2 class="step4Title">Sorry, your application was rejected.</h2>
        <div class="step4Tips">Reason: {{ reason }}</div>
      </div>
      <!-- <div class="step4Container" v-if="status === 8">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Title">To be activated</div>
      </div> -->
      <div class="step4Container" v-if="status === 52">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">Under Review</div>
      </div>
      <div class="step4Container" v-if="status === 53">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">Under Review</div>
      </div>
      <div class="step4Container" v-if="status === 54">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Tips">Under Review</div>
      </div>

      <!-- <div class="step4Container" v-if="status === 6">
        <a-icon
          class="topImg"
          type="clock-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Title">Under Review</div>
        <div class="step4Tips">
          Upon successful verification, the CVV will be sent to you via mail. In
          case you don't receive the e-mail shortly thereafter, please contact
          us via mail.You can view application status on My Account page .
        </div>
      </div> -->
      <!-- <div
        class="step4Container"
        v-if="status === 1 || status === 2 || status === 52"
      >
        <a-icon
          class="topImg"
          type="check-circle"
          theme="twoTone"
          :style="{ 'font-size': '100px' }"
        />
        <div class="step4Title">Under Review</div>
        <div class="step4Tips">
          Upon successful verification, the CVV will be sent to you via mail. In
          case you don't receive the e-mail shortly thereafter, please contact
          us via mail.You can view application status on My Account page .
        </div>
      </div> -->
    </div>
    <div class="stepsButtonContainer">
      <!-- <a-button
        v-if="
          status === 0 ||
          status === 1 ||
          status === 2 ||
          status === 6 ||
          status === 51 ||
          status === 52
        "
        class="stepsButton"
        type="primary"
        @click="goCard"
      >
        My Account
      </a-button> -->
      <a-button
        v-if="status === 7"
        class="stepsButton"
        type="primary"
        @click="changeCurrent(0)"
      >
        Re-submit Application
      </a-button>
      <!-- <a-button
        v-if="status === 8"
        @click="activeCard"
        class="stepsButton"
        type="primary"
      >
        Active Card
      </a-button> -->
      <!-- <a-button
        v-if="status === 1"
        @click="seeCardDetails"
        class="stepsButton"
        type="primary"
      >
        Card Details
      </a-button> -->
    </div>
    <!-- <a-modal
      v-model="sendMailVisible"
      title="Active Card"
      :footer="null"
      :maskClosable="false"
      centered
    >
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="Email verification code">
          <a-row :gutter="8">
            <a-col :span="16">
              <a-input
                v-decorator="[
                  'email-code',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter the captcha you got!',
                      },
                    ],
                  },
                ]"
              />
            </a-col>
            <a-col :span="8">
              <a-button class="sendMailBtn" @click="getCaptcha"
                >Send Email</a-button
              >
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item class="confirmContainer">
          <a-button class="goToBtn" html-type="submit"> Confirm </a-button>
        </a-form-item>
      </a-form>
    </a-modal> -->
  </a-spin>
</template>

<script>
import { sendEmail } from '@/utils/api';
import { reasonOptions } from '@/utils/enum';

export default {
  name: 'Step4',
  props: {
    id: {
      type: String,
      require: false,
      default: '',
    },
    status: {
      type: Number,
      require: true,
      default: 0,
    },
    kycMsg: {
      type: Object,
      require: true,
      default: () => {},
    },
    changeCurrent: {
      type: Function,
      require: true,
    },
    getKycDetail: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      loading: false,
      sendMailVisible: false,
      form: this.$form.createForm(this, { name: 'seeCVV' }),
      mail_code_token: '',
      reason: '',
    };
  },
  created() {
    reasonOptions.forEach((item) => {
      if (this.kycMsg && item.value === this.kycMsg.reason) {
        this.reason = item.en;
      }
    });
  },
  methods: {
    goCard() {
      this.$router.push({
        path: 'kyc-card',
      });
    },
    activeCard() {
      this.sendMailVisible = true;
    },
    seeCardDetails() {
      this.$router.push({
        path: '/cardDetails',
        query: {
          id: this.kycMsg.card_no,
        },
      });
    },
    getCaptcha() {
      const params = {
        mail: localStorage.getItem('inst_mail'),
      };
      sendEmail(params).then((res) => {
        this.mail_code_token = res.result;
        this.$message.success('send email success');
      });
    },
    // handleSubmit(e) {
    //   e.preventDefault();
    //   this.form.validateFields((err, values) => {
    //     if (!err) {
    //       let params = {
    //         card_no: this.kycMsg.card_no,
    //         token: this.mail_code_token,
    //         email_token: values["email-code"],
    //       };
    //       activeCard(params).then((res) => {
    //         if (res.code === 0) {
    //           this.$message.success("Active Card Success");
    //           this.sendMailVisible = false;
    //           this.getKycDetail();
    //         }
    //       });
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.step4Container {
  .topImg {
    width: 100%;
    margin: 40px auto 0;
    text-align: center;
  }
  .step4Title {
    text-align: center;
    font-family: SamsungSharpSans-Bold;
    margin-top: 30px;
  }
  h2 {
    margin: auto;
    margin-top: 24px;
  }
  .step4Tips {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    opacity: 0.5;
    margin-top: 24px;
  }
}
.stepsButtonContainer {
  margin-top: 60px;
  text-align: center;
  .stepsButton {
    width: 300px;
    height: 60px;
    border-radius: 8px;
    margin-right: 20px;
    @media (max-width: 960px) {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
.sendMailBtn {
  background: #0045ff;
  width: 100%;
  color: #fff;
}
.confirmContainer {
  text-align: center;
  .goToBtn {
    background: #0045ff;
    border-radius: 8px;
    height: 48px;
    width: 200px;
    color: #fff;
  }
}
</style>
