<template>
  <div>
    <div class="stepContainer">
      <div class="step3Container">
        <div class="registerTitle">Application Fee</div>
        <div class="registerFee">
          <a-space :size="10" direction="horizontal">
            <div class="amount">{{ cardApplicationFee || 5 }}</div>
            <div class="currency">{{ cardFeeCurrency }}</div>
          </a-space>
        </div>
        <div class="describe">Your card will be ready after the payment</div>
      </div>
      <!-- <a-space class="inputContent" :size="20" direction="vertical">
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Payment Crypto</div>
          <a-input class="stepInput" value="USDT" :disabled="true" />
        </a-space>
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Payment Amount</div>
          <a-input class="stepInput" value="5" :disabled="true" />
        </a-space>
      </a-space> -->
    </div>
    <div class="stepsButtonContainer">
      <a-button class="stepsButton" type="primary" :loading="loading" @click="runCharge">
        Pay
      </a-button>
    </div>
    <a-modal v-model="rechargeVisible" centered :footer="null" width="450" :maskClosable="false">
      <iframe :src="rechargeUrl" height="800" width="400" frameBorder="no">
      </iframe>
    </a-modal>
    <a-modal
      v-model="depositAddressVisible"
      centered
      :footer="null"
      width="800px"
      :maskClosable="false"
    >
      <div class="modalContainer">
        <div class="containerLeft">
          <qrcode-vue :value="depositAddress" size="140"></qrcode-vue>
        </div>
        <div class="containerRight">
          <div class="address">
            <div class="addressDeposit">
              Asset Name:
            </div>
            <div class="addressText">
              {{ depositAddressCoinType }}
            </div>
          </div>
          <div class="address">
            <div class="addressDeposit">
              Deposit Address:
            </div>
            <div class="addressText">
              <span class="text">{{ depositAddress }}</span>
              <span
                class="copyABtn"
                v-clipboard:copy="depositAddress"
                v-clipboard:success="onAddressCopy"
                v-clipboard:error="onAddressError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modalHashInputContainer">
        <div class="inputContent">
          <div class="inputLabel">Transaction Hash</div>
          <a-input class="stepInput" :value="tx_hash" placeholder="Please enter transaction hash"
            @change="hashChange" />
        </div>
      </div>
      <!-- <div class="modalDepositCreditCardTips">Note:</div>
      <div class="modalDepositCreditCardTipsContainer">
        About card activation:
        <br />
        * Please ensure the total value in your crypto account is higher than 100 USDT.
        <br />
        * When the balance in the crypto account is less than 100 USDT,
        you cannot use the card to pay for purchases, but
        you can still withdraw the cryptos.
        <br />
        * Once the transfer is confirmed, please enter the corresponding transaction hash.
      </div> -->
      <div class="modalDepositGoToBtnContainer">
        <a-button class="modalDepositGoToBtn" :disabled='submitDepositDisabled' type='primary'
          @click="submitDepositHash">
          Confirm </a-button>
      </div>

    </a-modal>
  </div>
</template>

<script>
import { rechargePayment, submitDepositTxHash } from '@/utils/api';
import { authAfter, neoAuthBefore } from '@/utils/config';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'Step3',
  props: {
    id: {
      type: String,
      require: true,
      default: '',
    },
    nextBtnClick: {
      type: Function,
      require: true,
    },
    kycMsg: {
      type: Object,
      require: true,
      default: () => { },
    },
    cardApplicationFee: {
      type: String,
      require: true,
      default: '5',
    },
    cardFeeCurrency: {
      type: String,
      require: true,
      default: 'USDT-TRC20',
    },
    currency: {
      type: String,
      require: true,
      default: 'USD',
    },
  },
  components: {
    QrcodeVue,
  },
  data() {
    return {
      loading: false,
      rechargeVisible: false,
      rechargeUrl: '',
      depositAddress: '',
      depositAddressCoinType: '',
      depositAddressVisible: false,
      tx_hash: '',
      submitDepositDisabled: true,
    };
  },
  created() { },
  methods: {
    runCharge() {
      const amount = this.cardApplicationFee || 5;

      const params = {
        amount,
        currency: 'USD',
        cust_order_id: Date.now(),
        card_type_id: this.id,
      };
      const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
      rechargePayment(params, authorization).then((res) => {
        if (res.code === 0) {
          if (res.result.status === 1 || res.result.status === '1') {
            this.nextBtnClick();
          } else if (res.result.type === 'fiat' && res.result.redirect_url) {
            this.rechargeVisible = true;
            this.rechargeUrl = res.result.redirect_url;
          } else if (res.result.type === 'USDT-TRC20' && res?.result?.address) {
            this.depositAddress = res.result.address;
            this.depositAddressVisible = true;
            this.depositAddressCoinType = res.result.type;
          }
        } else {
          // this.$message.error(res.msg);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    hashChange(e) {
      this.submitDepositDisabled = !e;
      this.tx_hash = e.target.value;

      // if (e.target.value.length === 64 || e.target.value.length === 66) {
      //   this.submitDepositDisabled = false;
      // } else {
      //   this.submitDepositDisabled = true;
      // }
    },
    submitDepositHash() {
      if (!this.tx_hash) {
        this.$message.error('Please enter transaction hash');
        return;
      }
      const params = {
        tx_hash: this.tx_hash,
        card_type_id: this.id,
        coin_type: this.depositAddressCoinType,
        address: this.depositAddress,
      };
      submitDepositTxHash(params).then((res) => {
        if (res.code === 0) {
          this.depositAddressVisible = false;
          this.nextBtnClick();
          this.$message.success(`${res.msg}`);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onAddressCopy() {
      this.$message.success('Copy Address Success');
    },
    onAddressError() {
      this.$message.error('Copy Address Failed');
    },
  },
};
</script>

<style lang="scss" scoped>
.stepContainer {
  .inputContent {
    width: 100%;

    .inputLabel {
      font-size: 16px;
    }

    .stepInput {
      height: 60px;
      border-radius: 8px;
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        height: 60px;
        border-radius: 8px;

        .ant-select-selection-item {
          line-height: 60px;
        }
      }
    }

    .ant-picker {
      height: 60px;
      width: 100%;
      border-radius: 8px;
    }

    .uploadBtn {
      margin-left: 20px;
      border-radius: 4px;
      font-size: 0;

      span {
        font-size: 14px;
      }

      img {
        height: 24px;
        margin-left: 10px;
      }
    }

    .idCardContainer {
      width: 100%;
      height: 200px;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      text-align: center;
      margin-bottom: 10px;

      img {
        height: 180px;
        margin-top: 10px;
      }
    }

    .uploadDescribe {
      display: block;
      font-size: 14px;
      line-height: 24px;
      opacity: 0.3;
    }

    .amountTips {
      text-align: end;

      span {
        font-size: 14px;
        opacity: 0.5;
      }
    }

    .inputBottom {
      color: #0045ff;
      font-size: 20px;
    }
  }

  .inputPayment {
    margin-top: 20px;

    .tipsLeft {
      width: 150px;
    }

    .inputPaymentTips {
      font-size: 16px;

      .tipsOpacity {
        font-size: 12px;
        opacity: 0.5;
      }

      .tipsWeight {
        margin: 0 10px;
      }
    }
  }

  .step3Container {
    text-align: center;
    margin-bottom: 60px;

    .registerTitle {
      font-size: 20px;
      line-height: 24px;
    }

    .registerFee {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .amount {
        color: #0045ff;
        font-size: 60px;
      }

      .currency {
        font-size: 20px;
      }
    }

    .describe {
      font-size: 14px;
      opacity: 0.5;
    }
  }

  .stepInputContainer {
    display: flex;
    flex-direction: row;

    .ant-select {
      width: 300px;
      margin-right: 40px;
    }

    .sendBtn {
      margin-left: 40px;
      height: 60px;
      width: 300px;
      border-radius: 8px;
    }
  }
}

.stepsButtonContainer {
  margin-top: 60px;
  text-align: center;

  .stepsButton {
    width: 150px;
    height: 48px;
    border-radius: 8px;
    margin-right: 20px;

    @media (max-width: 960px) {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

.modalContainer {
  display: flex;
  align-items: center;

  @media (max-width: 750px) {
    flex-direction: column;
  }

  .containerLeft {
    width: 180px;
    height: 180px;
    padding: 20px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    margin-right: 40px;

    @media (max-width: 750px) {
      margin-right: 0;
    }
  }

  .containerRight {
    @media (max-width: 750px) {
      width: 100%;
      margin-top: 40px;
    }

    .address {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 12px;
      color: #000;

      @media (max-width: 750px) {
        margin-bottom: 0;
      }

      .addressDeposit {
        font-family: "Poppins-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        mix-blend-mode: normal;
        opacity: 0.4;
        margin-bottom: 18px;
      }

      .addressText {
        .text {
          font-family: "Poppins-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          color: #000000;
        }

        .copyABtn {
          margin-left: 4px;
        }
      }
    }

    .ant-row {
      .ant-form-item-required {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

.modalHashInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;

  .inputContent {
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (max-width: 960px) {
      width: 100%;
    }

    .inputLabel {
      font-size: 20px;
      line-height: 24px;
      width: 200px;
      margin-bottom: 18px;
    }

    /deep/ .ant-space-item {
      flex: 1;
    }

    .stepInput {
      height: 48px;
      border-radius: 8px;

      /deep/ input {
        height: 48px;
      }
    }
  }
}

.modalDepositGoToBtnContainer {
  width: 100%;
  text-align: center;
  margin-top: 40px;

  .modalDepositGoToBtn {
    width: 400px;
    height: 48px;

    @media (max-width: 750px) {
      width: 100%;
    }
  }
}
</style>
