<template>
  <div>
    <a-spin :spinning="addressLoading">
      <div class="stepCreditContainer">
        <div class="step3ContainerLeft">
          <div class="hashInputContainer">
            <div class="hashQaCodeLeft">
              <qrcode-vue :value="qaValue" size="140"></qrcode-vue>
            </div>
            <div class="inputContent">
              <div class="inputLabel">Your Wallet Address</div>
              <a-select :value="wallerAddress" @change="walletAddressChange">
                <a-select-option
                  v-for="item of walletAddressOption"
                  :value="item.value"
                  :key="item.value"
                >
                  <img
                    :src="item.logo"
                    :style="{
                      height: '24px',
                      width: '24px',
                      marginRight: '5px',
                      marginTop: '-3px',
                    }"
                  />
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <div class="address">
                {{qaValue}}
                <span
                v-clipboard:copy="qaValue"
                v-clipboard:success="onAddressCopy"
                v-clipboard:error="onAddressError">
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
          </div>
          <div class="hashInputContainer hashInputContainerLaster">
            <div class="inputContent">
              <div class="inputLabel">Transaction Hash</div>
              <a-input
                class="stepInput"
                :value="tx_hash"
                placeholder="Please enter transaction hash"
                @change="hashChange"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="creditCardTipsContainer">
        About card activation:
        <br/>
        * Please ensure the total value in your crypto account is higher than 20 USDT.
        <br/>
          * When the balance in the crypto account is less than 20 USDT, you
           cannot use the card to pay for purchases, but you can still withdraw the cryptos.
        <br/>
        * Once the transfer is confirmed, please enter the corresponding transaction hash.
      </div>
      <div class="stepsButtonContainer">
        <a-button
          class="stepsButton"
          type="primary"
          :loading="loading"
          :disabled="submitDisabled"
          @click="submitHash"
        >
          Next
        </a-button>
      </div>
    </a-spin>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import {
  getAccountKycCard,
  submitTxHash,
  submitAccountKycCard,
} from '@/utils/api';
import NEO from '@/assets/NEO.png';
import GAS from '@/assets/gasImg.svg';
import USDT from '@/assets/USDT.png';

export default {
  name: 'Step3',
  props: {
    id: {
      type: String,
      require: true,
      default: '',
    },
    nextBtnClick: {
      type: Function,
      require: true,
    },
    cardApplicationFee: {
      type: String,
      require: true,
      default: '',
    },
    cardFeeCurrency: {
      type: String,
      require: true,
      default: '',
    },
  },
  components: { QrcodeVue },
  data() {
    return {
      loading: false,
      walletAddressOption: [],
      kycMsg: {},
      list_credit_asset: [],
      tx_hash: '',
      wallerAddress: 'NEO',
      submitDisabled: true,
      addressLoading: true,
      qaValue: '',
    };
  },
  created() {
    const params = {
      cardTypeId: this.$route.query.cardTypeId,
    };
    getAccountKycCard(params)
      .then((res) => {
        this.addressLoading = false;
        if (res.code === 0) {
          [this.kycMsg] = res.result;
          this.list_credit_asset = res.result[0].list_credit_asset;
          res.result[0].list_credit_asset.forEach((item) => {
            if (item.coin_type === 'NEO') {
              this.qaValue = item.address;
              this.walletAddressOption.push({
                value: item.coin_type,
                label: 'NEO(N3)',
                logo: NEO,
              });
            } else if (item.coin_type === 'GAS') {
              this.walletAddressOption.push({
                value: item.coin_type,
                label: 'GAS(N3)',
                logo: GAS,
              });
            } else if (item.coin_type === 'USDT-TRC20') {
              this.walletAddressOption.push({
                value: item.coin_type,
                label: 'USDT-TRC20',
                logo: USDT,
              });
            } else {
              this.walletAddressOption.push({
                value: item.coin_type,
                label: item.coin_type,
              });
            }
          });
        }
      })
      .catch(() => {
        this.addressLoading = false;
      });
  },
  // watch: {
  //   depositAddress: {
  //     handler(newV) {
  //       this.depositAddress = JSON.parse(JSON.stringify(newV));
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    onAddressCopy() {
      this.$message.success('Copy Address Success');
    },
    onAddressError() {
      this.$message.error('Copy Address Failed');
    },
    walletAddressChange(e) {
      this.wallerAddress = e;
      this.list_credit_asset.forEach((item) => {
        if (item.coin_type === e) {
          this.qaValue = item.address;
        }
      });
    },
    hashChange(e) {
      this.tx_hash = e.target.value;
      if (e.target.value.length === 64 || e.target.value.length === 66) {
        this.submitDisabled = false;
      } else {
        this.submitDisabled = true;
      }
    },
    submitHash() {
      const params = {
        tx_hash: this.tx_hash,
        card_type_id: this.$route.query.cardTypeId,
        coin_type: this.wallerAddress,
      };
      submitTxHash(params).then((res) => {
        if (res.code === 0) {
          const param = {
            ...this.kycMsg,
            status: 54,
          };
          submitAccountKycCard(param).then((kycRes) => {
            this.$message.success(`${kycRes.msg}`);
            this.nextBtnClick();
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stepCreditContainer {
  display: flex;
  justify-content: space-between;
  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
  .step3ContainerLeft {
    flex: 1;
    @media (max-width: 960px) {
      width: 100%;
    }
    .hashInputContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 960px) {
        flex-direction: column;
      }
      .hashQaCodeLeft {
        text-align: center;
        height: 200px;
        width: 200px;
        margin-right: 55px;
        padding: 30px 0;
        border: 1px solid #cccccc;
        border-radius: 10px;
        @media (max-width: 960px) {
          margin-right: 0;
          margin-bottom: 24px;
        }
      }
      .inputContent {
        display: flex;
        flex: 1;
        flex-direction: column;
        @media (max-width: 960px) {
          width: 100%;
        }
        .address {
          margin-top: 18px;
          font-family: "Poppins-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #000000;
          word-wrap: break-word;
          .copyAddress {
            cursor: pointer;
          }
        }
        .inputLabel {
          font-size: 20px;
          line-height: 24px;
          width: 200px;
          margin-bottom: 18px;
          opacity: 0.4;
        }
        /deep/ .ant-space-item {
          flex: 1;
        }
        .stepInput {
          height: 60px;
          border-radius: 8px;
          /deep/ input {
            height: 60px;
          }
        }
        .ant-select {
          flex: 1;
          height: 60px;
          /deep/ .ant-select-selection {
            height: 60px !important;
            border-radius: 8px;
            .ant-select-selection__rendered {
              height: 60px !important;
              line-height: 60px;
            }
          }
        }
        .ant-select {
          width: 100%;
          height: 60px;
          /deep/ .ant-select-selection {
            height: 60px !important;
            border-radius: 8px;
            .ant-select-selection__rendered {
              height: 60px !important;
              line-height: 60px;
            }
          }
        }
      }
    }
    .hashInputContainerLaster {
      margin-top: 30px;
    }
  }
}
.creditCardTipsContainer {
  background: #fafafa;
  border-radius: 8px;
  padding: 30px;
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #999999;
  margin-top: 40px;
}
.stepsButtonContainer {
  margin-top: 40px;
  text-align: center;
  .stepsButton {
    width: 150px;
    height: 48px;
    border-radius: 8px;
    margin-right: 20px;
    @media (max-width: 960px) {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
</style>
