<template>
  <div>
    <div class="stepContainer">
      <a-space class="inputContainer" :size="20">
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Date of birth</div>
          <a-date-picker
            :value="dateString"
            :disabled="kycMsg.birthday ? true : false"
            @change="dateChange"
            placeholder="Select your birthday"
          />
        </a-space>
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Nationality</div>
          <a-select
            :value="nationality"
            :disabled="true"
            @change="(value) => (this.nationality = value)"
          >
            <a-select-option
              v-for="item in countryOption"
              :key="item.key"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-space>
      </a-space>
      <a-space class="inputContainer" :size="20">
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">ID type</div>
          <a-select
            :value="idType"
            :options="idTypeOption"
            :disabled="true"
            @change="(value) => (this.idType = value)"
          />
        </a-space>
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">Passport Number</div>
          <!-- <div class="inputLabel" v-else>Driver's License Number</div> -->
          <a-input
            class="stepInput"
            :value="idNumber"
            placeholder="Please enter document number"
            :disabled="kycMsg.doc_no ? true : false"
            @change="(e) => (this.idNumber = e.target.value)"
          />
        </a-space>
      </a-space>
      <a-space class="inputContainer" :size="20">
        <a-space
          v-if="idType === 'driving'"
          class="inputContent"
          :size="20"
          direction="vertical"
        >
          <div class="inputLabel">
            Passport Photo
            <a-upload
              name="file"
              :showUploadList="false"
              :action="uploadUrl"
              :headers="headers"
              maxCount="1"
              :before-upload="beforeUpload"
              @change="handleFrontChange"
            >
              <a-button class="uploadBtn" type="primary">
                <span>
                  upload
                  <img src="@/assets/cloudUpload.svg" alt="upload" />
                </span>
              </a-button>
            </a-upload>
          </div>
          <div>
            <div class="idCardContainer">
              <a-spin :spinning="firstFrontLoading">
                <img
                  v-if="firstFrontHttpImgUrl || firstFrontImgUrl"
                  :src="firstFrontHttpImgUrl || firstFrontImgUrl"
                  alt="front card"
                />
                <img
                  v-else
                  src="@/assets/passwordImg.svg"
                  alt="front card"
                />
              </a-spin>
            </div>
          </div>
        </a-space>
        <a-space class="inputContent" :size="20" direction="vertical">
          <div class="inputLabel">
            Selfie with passport
            <a-upload
              name="file"
              :showUploadList="false"
              :action="uploadUrl"
              :headers="headers"
              maxCount="1"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <a-button class="uploadBtn" type="primary">
                <span>
                  upload
                  <img src="@/assets/cloudUpload.svg" alt="upload" />
                </span>
              </a-button>
            </a-upload>
          </div>
          <div>
            <div class="idCardContainer">
              <a-spin :spinning="frontLoading">
                <img
                  v-if="frontHttpImgUrl || frontImgUrl"
                  :src="frontHttpImgUrl || frontImgUrl"
                  alt="front card"
                />
                <img
                  v-else
                  src="@/assets/passwordHoldImg.png"
                  alt="front card"
                />
              </a-spin>
            </div>
          </div>
        </a-space>
      </a-space>
      <span class="uploadDescribe"
        >Make sure the text on the passport is clear (please do not use selfie
        mode or mirror the photo); your entire face is visible, and your
        shoulders and arms are included in the photo.</span
      >
    </div>
    <div class="stepsButtonContainer">
      <!-- <a-button class="stepsButton" type="primary" @click="backBtnClick">
        Back
      </a-button> -->
      <a-button class="stepsButton" type="primary" @click="submitKyc">
        Next
      </a-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import countryList from '@/utils/Country.json';
import { getAccountKycCard, submitAccountKycCard } from '@/utils/api';
import { baseAdminUrl } from '@/utils/baseUrl';

export default {
  name: 'Step2',
  props: {
    id: {
      type: String,
      require: false,
      default: '',
    },
    kycMsg: {
      type: Object,
      require: true,
      default() {
        return {};
      },
    },
    card_fee_order_no: {
      type: String,
      require: false,
      default: '',
    },
    nextBtnClick: {
      type: Function,
      require: true,
    },
    backBtnClick: {
      type: Function,
      require: true,
    },
    changeCurrent: {
      type: Function,
      require: true,
    },
    changeStatus: {
      type: Function,
      require: true,
    },
    isPaidBtnClick: {
      type: Function,
      require: true,
    },
  },
  components: {},
  data() {
    return {
      countryOption: [],
      idTypeOption: [{ label: 'Passport', value: 'passport' }],
      dateString: null,
      nationality: '',
      idType: '',
      idNumber: '',
      headers: {
        authorization: localStorage.getItem('inst_money_access_token') || '',
      },
      frontLoading: false,
      frontHttpImgUrl: '',
      frontImgUrl: '',
      uploadUrl: `${baseAdminUrl}/v1/common/file/public`,
      firstFrontLoading: false,
      firstFrontHttpImgUrl: '',
      firstFrontImgUrl: '',
    };
  },
  mounted() {
    const initCountry = countryList.map((item) => {
      const tmp = {
        value: item.countryCode,
        label: item.name_en,
        code: item.code,
        key: item.name_cn,
      };
      return tmp;
    });
    this.countryOption = initCountry;
    this.nationality = this.kycMsg.nationality;
    this.idType = this.kycMsg.doc_type;
    this.dateString = this.kycMsg.birthday
      ? moment(this.kycMsg.birthday).format('YYYY-MM-DD')
      : '';
    this.idNumber = this.kycMsg.doc_no;
    this.frontHttpImgUrl = this.kycMsg.mix_doc;
    if (this.kycMsg.doc_type !== 'driving') {
      this.firstFrontHttpImgUrl = this.kycMsg.front_doc;
    }
  },
  methods: {
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG/PNG file!');
      }
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        this.$message.error('Image must smaller than 4MB!');
      }
      return isJpgOrPng && isLt4M;
    },
    dateChange(date, dateString) {
      this.dateString = dateString;
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.frontLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        this.frontLoading = false;
        if (info?.file?.response?.code === 0) {
          this.frontHttpImgUrl = info?.file?.response?.result;
          this.getBase64(info.file.originFileObj, (imageUrl) => {
            this.frontImgUrl = imageUrl;
          });
        }
      }
    },
    handleFrontChange(info) {
      if (info.file.status === 'uploading') {
        this.firstFrontLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        this.firstFrontLoading = false;
        if (info?.file?.response?.code === 0) {
          this.firstFrontHttpImgUrl = info?.file?.response?.result;
          this.getBase64(info.file.originFileObj, (imageUrl) => {
            this.firstFrontImgUrl = imageUrl;
          });
        }
      }
    },
    submitKyc() {
      // this.isPaidBtnClick();
      const param = {
        cardTypeId: this.id,
      };
      getAccountKycCard(param).then((res) => {
        if (res.result) {
          const params = {
            ...res.result[0],
            card_type_id: this.id,
            doc_type: this.idType,
            birthday: moment(this.dateString).format('x'),
            doc_no: this.idNumber,
            nationality: this.nationality,
            mix_doc: this.frontHttpImgUrl,
            front_doc: this.frontHttpImgUrl,
            status: 52,
          };
          if (this.idType === 'driving') {
            params.front_doc = this.firstFrontHttpImgUrl;
          }
          if (this.idType === 'driving' && !this.firstFrontHttpImgUrl) {
            this.$message.error('Please upload your passport photo');
          } else if (!this.nationality) {
            this.$message.error('Please select your nationality');
          } else if (!this.idType) {
            this.$message.error('Please enter your ID type');
          } else if (!this.dateString) {
            this.$message.error('Please select your birthday');
          } else if (!this.idNumber) {
            if (this.idType === 'passport') {
              this.$message.error('Please enter your Passport Number');
            } else {
              this.$message.error("Please enter your Driver's License Number");
            }
          } else if (!this.frontHttpImgUrl) {
            this.$message.error('Please upload your selfie with passport');
          } else {
            submitAccountKycCard(params).then((kycCardRes) => {
              this.$message.success(`${kycCardRes.msg}`);
              if (this.card_fee_order_no) {
                this.changeStatus(52);
                this.changeCurrent(3);
              } else {
                this.nextBtnClick();
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stepContainer {
  .inputContainer {
    margin-top: 20px;
    @media (max-width: 960px) {
      display: block;
      /deep/ .ant-space-item {
        width: 100%;
      }
    }
    width: 100%;
    /deep/ .ant-space-item {
      flex: 1;
    }
    .inputContent {
      width: 100%;
      .inputLabel {
        font-size: 16px;
      }
      .stepInput {
        height: 60px;
        border-radius: 8px;
        /deep/ input {
          height: 60px;
        }
      }
      .ant-select {
        width: 100%;
        height: 60px;
        /deep/ .ant-select-selection {
          height: 60px !important;
          border-radius: 8px;
          .ant-select-selection__rendered {
            height: 60px !important;
            line-height: 60px;
          }
        }
      }
      .ant-calendar-picker {
        height: 60px;
        width: 100%;
        border-radius: 8px;
        /deep/ .ant-calendar-picker-input {
          height: 60px;
        }
      }
      .uploadBtn {
        margin-left: 20px;
        border-radius: 4px;
        font-size: 0;
        span {
          font-size: 14px;
        }
        img {
          height: 24px;
          margin-left: 10px;
        }
      }
      .idCardContainer {
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        margin-bottom: 10px;
        text-align: center;
        padding: 20px;
        img {
          max-width: 90%;
          height: 180px;
        }
      }
      .amountTips {
        text-align: end;
        span {
          font-size: 14px;
          opacity: 0.5;
        }
      }
      .inputBottom {
        color: #0045ff;
        font-size: 20px;
      }
    }
  }
  .uploadDescribe {
    display: block;
    font-size: 14px;
    line-height: 24px;
    opacity: 0.3;
  }
  .stepInputContainer {
    display: flex;
    flex-direction: row;
    .ant-select {
      width: 300px;
      margin-right: 40px;
    }
    .sendBtn {
      margin-left: 40px;
      height: 60px;
      width: 300px;
      border-radius: 8px;
    }
  }
}
.stepsButtonContainer {
  margin-top: 60px;
  text-align: center;
  .stepsButton {
    width: 300px;
    height: 60px;
    border-radius: 8px;
    margin: 0 8px 20px;
    @media (max-width: 960px) {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
</style>
